import React from "react";
import { graphql } from "gatsby";
import HeroContainer from "../components/HeroContainer";
import TaggedTextWithDescription from "../components/TaggedTextWithDescription";
import SEO from "../components/SEO";
import Button from "../components/Button";
import { AnimatedText, AnimatedRandomX } from "../components/Animations";
import styled from "@emotion/styled";
import TechCard from "../components/TechCard";
import rocket from "../../assets/images/about-rocket.svg";
import pilars from "../../assets/images/illustration3.svg";

const RandomBlockX = (props) => (
  <AnimatedRandomX {...props} initialPose="exit" pose="enter" />
);

export const pageQuery = graphql`
  query AboutQuery {
    prismicSeo(data: { name: { eq: "Mosano | About" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
    allPrismicTech(sort: { order: ASC, fields: data___sort_order }) {
      nodes {
        data {
          description
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  tracedSVGOptions: { background: "transparent", color: "#333" }
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
            alt
          }
          title
        }
      }
    }
    allPrismicClient {
      nodes {
        data {
          name
          logo {
            alt
            url
          }
        }
      }
    }
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;

const About = (props: any) => {
  const allClients = props.data.allPrismicClient.nodes || [];
  const allTech = props.data.allPrismicTech.nodes || [];

  return (
    <>
      <SEO {...props.data.prismicSeo.data} />
      <div className="hero-about">
        <HeroContainer
          heroType="about"
          titleClass="full center white"
          title="Commitment. Transparency. Professionalism."
          paragraphClass="center p1 yellow max"
          paragraph={
            <AnimatedText duration={3000}>
              We help companies across the world deliver cutting edge technology
              that changes Industries.
            </AnimatedText>
          }
          scroll
        />
        <RandomBlockX className="div-block-89" />
        <RandomBlockX className="div-block-88" />
        <RandomBlockX className="div-block-21" />
      </div>
      <div className="process">
        <div className="container-process">
          <div className="mosano-values">
            <div className="flex-row-reverse">
              <TaggedTextWithDescription
                subtitle="How we started"
                capClass="about-cap"
                title="Crafting Teams & Digital Products."
                titleClass="full values-max"
                body="With the right mindset and goal oriented, in 2017, we founded Mosano, to help companies achieve their goals through technology, and at the same time to create our own products and teams. Since then, we have helped dozens of companies across the world to create, support and maintain their digital products."
                bodyClass="p2 full values-max"
              />
            </div>
            <div className="flex-row">
              <img
                src={rocket}
                alt="rocket image"
                className="about-rocket mosano-flex"
              />
            </div>
          </div>
          <div className="mosano-values-reverse">
            <div className="flex-row-reverse">
              <img
                src={pilars}
                alt="pilars image"
                className="about-rocket mosano-flex"
              />
            </div>
            <div className="flex-row">
              <TaggedTextWithDescription
                subtitle="What we value"
                capClass="about-cap"
                title="We believe in long-term relationships"
                titleClass="full values-max"
                body="Commitment, Transparency and Professionalism, that's how we present ourselves. We look for partners that value our principles, and that we can build long-lasting relationships."
                bodyClass="p2 full values-max"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="how-we-started">
        <div className="container-how-we-started">
          <TaggedTextWithDescription subtitle="Technologies" />
          <h1 className="heading center medium">
            Leveraging our clients with the right tools
          </h1>
          <p className="p2 large center tech-text">
            We are proud to work with cloud services, tools, programming
            languages, frameworks, and libraries that we believe to be the
            hightest level of quality and reliability.
          </p>
          <div className="line-30" />
          <div className="line-31" />
        </div>
      </div>
      <div className="tech-stack">
        {allTech.map((tech, index) => (
          <TechCard
            key={`tech-card#${index}`}
            title={tech.data.title}
            description={tech.data.description}
            source={tech.data.logo}
          />
        ))}
      </div>
      <div className="clients">
        <div className="container-clients w-container">
          <div>
            <div className="cap-symbol cap-blue">{"// "}</div>
            <div className="cap cap-blue">our clients</div>
          </div>
          <h1 className="heading center-small">
            Special people that already place their trust in us
          </h1>
          <p className="p2 center-2">
            We build digital products for startups, local companies, global
            companies, global enterprises, and even some Fortune 500 companies.
            Each type of organization brings its challenges, but our goal always
            remains the same: to empower their business.
          </p>
        </div>
        <div className="container-logo-clients">
          {allClients.map((client, index) => (
            <div className="client-profile" key={`client-${index}`}>
              <ClientImg
                src={client.data.logo.url}
                alt={client.data.logo.alt}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="partner">
        <div className="container-partner">
          <div className="wrapper-partner">
            <h1 className="heading large-3 white">Should we partner up?</h1>
            <p className="p2 white">
              You are one step away from understanding if the services we
              provide are what you are looking for.
            </p>
            <div className="div-block-119" />
            <div className="button-row">
              <Button
                spacer={false}
                buttonClass="button-container-details"
                buttonHref="/services"
                buttonHrefClass="button green w-button"
                buttonLabel="check our services"
                buttonLine="button-box green"
              />
              <Button
                spacer={false}
                outerClass="spacer-button-left"
                buttonClass="button-container-details"
                buttonHref="/contact"
                buttonHrefClass="button green w-button"
                buttonLabel="contact us"
                buttonLine="button-box green"
              />
            </div>
          </div>
          <div className="div-block-120" />
        </div>
      </div>
    </>
  );
};

const ClientImg = styled.img`
  width: 200px;
  height: 200px;
`;

About.propTypes = {};

export default About;
