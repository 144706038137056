import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const TechCard = (props: any) => (
  <div className="tech-card">
    <GatsbyImage
      image={props.source.localFile.childImageSharp.gatsbyImageData}
      className="tech-logo"
      imgStyle={{ objectFit: "contain" }}
      alt={props.source.alt}
    />
    <h2 className="tech-title">{props.title}</h2>
    <p className="tech-paragraph">{props.description}</p>
  </div>
);

export default TechCard;

TechCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  source: PropTypes.object,
};
